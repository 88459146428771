@import './styling/colors';

html,
body {
    padding: 0;
    margin: 0;
}

.app {
    color: black;
    font-family: 'Raleway', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        width: 100%;

        .link {
            text-decoration: none;
            color: inherit;
        }
    }
}

button {

    width: max-content;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    background-color: white;
    border: 1px solid $darkPurple;
    border-radius: 5px;
    color: $darkPurple;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    &:hover {
        background-color: $darkPurple;
        color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
}