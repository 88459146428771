.redir {
    display: flex;
    justify-content: center;

    .centered {
        width: 90%;
        max-width: 1024px;
        min-height: 700px;

        .desc {
            margin-top: 40px;
        }

        .textWrap {
            margin-top: 30px;
            display: flex;
            justify-content: center;
        }

        .pdf {
            margin-bottom: 5px;
        }
    }
}