@import './colors';

footer {
    height: 60px;
    margin-top: 50px;
    padding: 10px 20px;
    background-color: $logoBlack;
    position: relative;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    font-size: 16px;
    font-weight: 300;
    color: white;
    letter-spacing: 1px;

    img {
        height: 60px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    span {
        position: absolute;
        right: 16px; /* Adjust the right distance as needed */
        bottom: 30px;
    }
}