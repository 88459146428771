@import './colors';

.dapot {
    display: flex;
    justify-content: center;

    .centeredDapot {
        width: 90%;
        max-width: 1024px;
        min-height: 700px;
        
        // background-color: green;

        .generalPres{
            margin-top: 50px;
            margin-bottom: 50px;

            .prestext {
                padding: 10px;
            }
            // background-color: blue;
        }
    }
}