@import './colors';

.scrisoare {
    display: flex;
    justify-content: center;
    min-height: 700px;
    // background-color: green;

    .centered {
        width: 90%;
        max-width: 1024px;
        margin-top: 50px;
        // background-color: red;

        .posts {
            display: flex;
            flex-direction: column;
            gap: 20px;

            h2{
                margin: 0;
            }

            .prestext {
                padding: 10px;
            }

            .post {
                h2 {
                    margin: 0;
                }
                
                .minCont {
                    width: min-content;
                }

                background-color: rgba(102, 51, 153, 0.098);
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 20px;

                .description {
                    text-align: justify;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 5;
                    white-space: pre-wrap;
                    max-height: 200px;
                    padding-left: 2%;
                    padding-right: 2%;
                    font-size: 16px;
                    font-weight: 300;
                }

                // .img {
                //     flex: 2;
                //     position: relative;

                //     &::after {
                //         content: "";
                //         width: 100%;
                //         height: 100%;
                //         background-color: $logoBlack;
                //         position: absolute;
                //         top: 20px;
                //         left: -20px;
                //         z-index: -1;
                //     }

                //     img {
                //         width: 100%;
                //         max-height: 400px;
                //         object-fit: cover;
                //     }
                // }

                
            }
            
            .separator {
                display: flex;
                align-items: center;
                justify-content: center;
    
                .line {
                    max-width: 400px;
                    width: 50%;
                    height: 1px;
                    background-color: #0000001f;
                }
            }
        }

        .postContent {
            // padding: 10px;
            // background-image: url('../resources/notebook.svg');
            // background-repeat: repeat-y;
            // background-size: contain;
            // background-color: red;
            .back{
                float: right;
                position: sticky;
                top: 10px;
            }

            .textWrap {
                // background-color: red;
                position: relative;
                padding-left: 7%;
                padding-right: 5%;
                padding-top: 3%;
                padding-bottom: 3%;
                
                .text {
                    p {
                        text-align: justify;
                    }
                }

                // filter: brightness(0.92) saturate(0.6) hue-rotate(250deg);
            }

            .textWrap::before {
                content: ' ';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                opacity: 0.9;
                background-image: url('../resources/notebook.svg');
                background-repeat: repeat-y;
                background-position: 50% 0;
                background-size: contain;
                // filter: brightness(0.92) saturate(0.6) hue-rotate(250deg);
            }
        }
    }
}
