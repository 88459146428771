@import './colors';

.navbar {
    display: flex;
    align-items: center;
    justify-content: center;

    .centeredCont {
        width: 100%;
        max-width: 1024px;

        .container {
            width: auto;
            padding: 15px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .logo {
                img {
                    width: 90px;
                }
            }

            .links {
                display: flex;
                align-items: center;
                gap: 30px;

                .mobileOnly {
                    display: none;
                }

                .link {
                    position: relative;
                    font-weight: 400;
                    font-size: 18px;
                }

                .link:not(.active)::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    border-radius: 4px;
                    background: linear-gradient(90deg, rgba(0, 166, 150, 1) 0%, rgba(45, 78, 151, 1) 20%, rgba(95, 66, 150, 1) 40%, rgba(154, 55, 148, 1) 60%, rgba(188, 45, 104, 1) 80%, rgba(241, 89, 42, 1) 100%);
                    bottom: 0;
                    left: 0;
                    transform-origin: right;
                    transform: scaleX(0);
                    transition: transform .3s ease-in-out;
                }

                .link:hover:not(.active)::before {
                    transform-origin: left;
                    transform: scaleX(1);
                }

                .active {
                    font-weight: 700;
                }

                .submenuContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: relative;
                    cursor: pointer;
                    align-items: center;
                    justify-content: center;
                    z-index: 1;
                    padding-top: 20px;
                    padding-bottom: 20px;

                    .navText {
                        font-weight: 400;
                        font-size: 18px;
                    }

                    .submenu {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        background-color: #fff;
                        // border: 0.1px solid $lightPurple;
                        gap: 10px;
                        border-radius: 5px;
                        padding: 20px;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

                        &::before {
                            content: '';
                            position: absolute;
                            top: -10px;
                            left: 50%;
                            transform: translateX(-50%);
                            border-width: 0 12px 10px 12px;
                            border-style: solid;
                            border-color: transparent transparent $lightPurple transparent;
                        }

                        button {

                            width: max-content;
                            padding: 10px 15px;
                            border: none;
                            cursor: pointer;
                            background-color: white;
                            border: 1px solid $darkPurple;
                            border-radius: 5px;
                            color: $darkPurple;

                            &:hover {
                                border: 1px solid white;
                                background-color: $darkPurple;
                                color: white;
                            }
                        }
                    }
                }


            }
        }

        .colorband {
            height: 5px;
            background: linear-gradient(90deg, rgba(0, 166, 150, 1) 0%, rgba(45, 78, 151, 1) 20%, rgba(95, 66, 150, 1) 40%, rgba(154, 55, 148, 1) 60%, rgba(188, 45, 104, 1) 80%, rgba(241, 89, 42, 1) 100%);
        }
    }
}

@media (max-width: 950px) {
    .navbar {
        .centeredCont {

            .container {
                width: auto;
                padding: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .logo {
                    img {
                        width: 60px;
                    }
                }

                .menu-toggle {
                    cursor: pointer;
                    .hambox{
                        position: relative;
                        width: 10px;
                        height: 10px;
                        cursor: pointer;
                        border-radius: 50%;
                        transition: 0.3s ease;
                        padding: 20px;
                        background: rgba(102, 51, 153, 0.098);
                    }
    
                    .hambox:hover {
                        .lineTop, .lineBottom {
                            background: white;
                        }
                        background: rgba(102, 51, 153, 0.858);
                    }
    
                    .hamboxOpen {
                        
                        background: rgba(102, 51, 153, 0.098);
                    }
                    .lineTop,
                    .lineBottom {
                        margin: 0 auto;
                        position: absolute;
                        display: block;
                        width: 24px;
                        height: 2px;
                        border-radius: 10px;
                        background: rebeccapurple;
                        left: 0;
                        right: 0;
                        transform: rotate(0deg);
                        transition: all 0.4s;
                    }
    
                    .lineTop {
                        top: 20px;
                    }
    
                    .lineBottom {
                        bottom: 20px;
                    }
    
                    .lineTop.spin {
                        top:24px;
                        transform: rotate(135deg);
                    }
    
                    .lineBottom.spin {
                        bottom: 24px;
                        transform: rotate(225deg);
                    }
                }

                .links {
                    padding-top: 30px;
                    position: absolute;
                    right: 0;
                    top: 116px;
                    width: 0px;
                    z-index: 2;
                    background-color: white;
                    height: calc(100vh - 116px);
                    transition: all 0.3s ease-in;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;

                    .link {
                        position: relative;
                        font-weight: 400;
                        font-size: 18px;
                    }

                    .invisibleLink {
                        opacity: 0;
                        transition: opacity 0.2s ease-in-out;
                    }

                    .visibleLink {
                        opacity: 1; // Initially set opacity to 0
                        transition: opacity 1s ease-in-out;
                    }

                    .active {
                        font-weight: 700;
                    }

                    .submenuContainer {
                        display: none;
                    }

                    .mobileOnly {
                        display: block;
                    }

                    .TextY {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 20px;

                        .implText {
                            font-weight: 200;
                        }

                        .LLine {
                            display: inline-block;
                            width: 200px; /* Adjust the width as needed */
                            height: 1px; /* Adjust the height as needed */
                            background-color: #0000003c; /* Set the desired color */
                        }
                    }

                    
                }

                .links.showMenu {
                    width: 100%;
                }
            }

            .colorband {
                height: 5px;
                background: linear-gradient(90deg, rgba(0, 166, 150, 1) 0%, rgba(45, 78, 151, 1) 20%, rgba(95, 66, 150, 1) 40%, rgba(154, 55, 148, 1) 60%, rgba(188, 45, 104, 1) 80%, rgba(241, 89, 42, 1) 100%);
            }
        }
    }
}